import { createUseStyles } from "react-jss";
import { Trans, useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import success from "../../../assets/animations/Success.json";
import cry from "../../../assets/animations/Cry.json";
import alert from "../../../assets/animations/Alert-1.json";
import Popover from "../../../components/Popover";
import Animation from "../../../components/Animation";
import Button from "../../../components/Button";
import PlanOfSubscription from "../SubscriptionsPlansModal/components/PlanOfSubscription/PlanOfSubscription";
import { PackageItem } from "../MorgisPackagesModal/MorgisPackagesModal";

const useStyles = createUseStyles((theme) => ({
    alertAnimation: {
        margin: "0 auto",
        height: "220px",
        width: "240px",
        padding: "15px",
        [theme.mUp]: {
            padding: 0,
        },
    },
    modalBody: {
        padding: '0 24px 24px',
        maxWidth: '394px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    modalRoot: {
        width: '394px',
        height: '600px',
        zIndex: 1030,
    },
    animationBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        flex: '1'
    },
    statusTitle: {
        fontSize: '24px',
        lineHeight: '33.6px',
    },
    statusInfo: {
        fontSize: '14px',
        lineHeight: '19.6px',
        textAlign: 'center',
    },
    statusDescription: {
        textAlign: 'center',
        color: '#B3B3B3',
    },
    subtitle: {
        textWrap: 'balance',
        textAlign: 'center',
        lineHeight: '140%', /* 19.6px */
    },
    '@media (max-width: 768px)': {
        modalRoot: {
            width: '100%',
            height: '100%',
            borderRadius: '0 !important',
        },
        root: {
            height: '100%',
        },
        modalBody: {
            margin: '0 auto',
        },
    },
}));

export const PaymentStepsVariants = {
    SUCCESS_PACKAGE: 'success-package',
    SUCCESS_SUBSCRIPTION: "success-subscription",
    FAILURE_PACKAGE: 'failure-package',
    FAILURE_SUBSCRIPTION: 'failure-subscription',
    FAILURE: 'failure'
}



//example how to use, TODO: Remove after add BE
// const dataToDisplay = {
//     Email: 'exampleEmail@gmail.com',
//     ROOKIE_NAME: 'Name',
// };

// <StepsModal variant="success-package" dataToDisplay={dataToDisplay} onClose={handleClose} />
// <StepsModal variant="success-subscription" dataToDisplay={dataToDisplay} onClose={handleClose} />
// <StepsModal variant="failure" dataToDisplay={dataToDisplay} onClose={handleClose} />


export const PaymentStepsModal = ({ variant = PaymentStepsVariants.FAILURE_SUBSCRIPTION, dataToDisplay, onClose }) => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const modalConfig = {
        [PaymentStepsVariants.FAILURE]: {
            animationData: cry,
            title: 'payment_steps.unsuccessful_title',
            status: 'payment_steps.unsuccessful_status',
            info: 'payment_steps.unsuccessful_info',
            buttonText: 'payment_steps.try_again',
        },
        [PaymentStepsVariants.SUCCESS_PACKAGE]: {
            animationData: success,
            title: 'payment_steps.title',
            status: 'payment_steps.payment_successful',
            info: 'payment_steps.success_status_package',
            description: 'payment_steps.status_description',
            buttonText: 'payment_steps.continue',
        }, [PaymentStepsVariants.SUCCESS_SUBSCRIPTION]: {
            animationData: success,
            title: 'payment_steps.title',
            status: 'payment_steps.payment_successful',
            info: 'payment_steps.success_status_subscription',
            description: 'payment_steps.status_description',
            buttonText: 'payment_steps.continue',
        },
        [PaymentStepsVariants.FAILURE_PACKAGE]: {
            title: 'payment_steps.voided_transaction_title',
            subtitle: 'payment_steps.voided_transaction_subtitle',
            animationData: alert,
            info: 'payment_steps.voided_transaction_info',
            errorContent: () => (
                <PackageItem
                    packageItem={{ id: 0, micromorgi_count: 100, price: 12 }}
                />),
            buttonText: t('payment_steps.voided_transaction_package_button', { AMOUNT: 100, PRICE: 12, CURRENT_SYMBOL: '$' }),

        },
        [PaymentStepsVariants.FAILURE_SUBSCRIPTION]: {
            title: 'payment_steps.voided_transaction_title',
            subtitle: 'payment_steps.voided_transaction_subtitle',
            animationData: alert,
            info: 'payment_steps.voided_transaction_info',
            errorContent: () => (
                <PlanOfSubscription
                    plan={{ id: 1, amount: 1000, dollar_amount: 100, surprises_count: 20 }}
                    currencySymbol={'$'}
                />),
            buttonText: t("payment_steps.voided_transaction_subscription_button", { HOST_NAME: '[hostName]' }),
        }
    };


    const modalData = modalConfig[variant];

    return (
        <Popover onClose={onClose}
            withHeader
            title={modalData.title ? t(modalData.title) : undefined}
            className={classes.modalRoot}
            bodyClassName={classes.modalBody}
            overlayZIndex={1030}
        >
            {modalData.subtitle &&
                <p className={classes.subtitle}>
                    {t(modalData.subtitle)}
                </p>
            }
            <div className={classes.animationBox}>
                {modalData.animationData &&
                    <Animation
                        animationData={modalData.animationData}
                        animationStyleClass={classes.alertAnimation}
                    />}
                {modalData.status &&
                    <div className={classes.statusTitle}>
                        {t(modalData.status)}
                    </div>}
                {modalData.info &&
                    <div className={classes.statusInfo}>
                        <Trans
                            t={t}
                            i18nKey={modalData.info}
                            components={{ underline: <br /> }}
                            values={dataToDisplay}
                        />
                    </div>}

                {modalData.description && (
                    <div className={classes.statusDescription}>
                        <Trans
                            t={t}
                            i18nKey={modalData.description}
                            components={{ underline: <br /> }}
                            values={dataToDisplay}
                        />
                    </div>
                )}
                {modalData.errorContent?.()}
            </div>
            <Button width={"100%"} data-primary onClick={onClose}>
                {modalData.buttonText ? t(modalData.buttonText) : ''}
            </Button>
        </Popover>
    );
};