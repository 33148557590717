import { useTranslation } from "react-i18next";
import FavTopics from "../../../components/FavTopics";
import { getAvailableNamespaces } from "../../../translations";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
    achievementsTitle: {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "28px",
        margin: "16px 0 16px 0",
    },
    backgroundBanner: {
        padding: "16px",
        borderRadius: "14px",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    },
    bio: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "300px",
        margin: 0,
        fontSize: 14,
        color: `${theme.colors.darkPurple}`,
        opacity: "50%",
    
        [theme.mUp]: {
          maxWidth: "100%",
        },
    },
}))

const RookieAboutSection = ({rookieProfile}) => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const {
        description,
        type_attributes: { path },
        chat_topics,
        favorite_activities,
    } = rookieProfile;

    return (
        <div>
            <h4 className={classes.achievementsTitle}>
                {t('rookie_profile_page.about_section_title')}
            </h4>
            <div className={classes.backgroundBanner}>
                <p className={classes.bio}>{description}</p>
                <FavTopics
                    viewedFavChatTopics={chat_topics}
                    viewedFavActivities={favorite_activities}
                    path={path}
                />
            </div>
        </div>
  )
}

export default RookieAboutSection