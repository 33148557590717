import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { ChatTopics, WishToBecomeIcon } from "../assets/icons/icons";
import activitiesIcon from "../assets/images/activities.png";
import { getAvailableNamespaces } from "../translations";

const useStyles = createUseStyles((theme) => ({
  sectionWrapper: {
    fontFamily: "Inter",
    marginTop: "14px",
    marginBottom: "24px",
  },
  topicWrapper: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  listWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  iconWrapper: {
    width: "24px",
    height: "24px",
    marginRight: "5px",
  },
  item: {
    marginRight: "2px",
  },
  wishesWrapper: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    marginTop: "8px",
  },
}));

function FavTopics({
  viewedFavChatTopics,
  viewedFavActivities,
  path,
}) {
  const classes = useStyles();
  const [t] = useTranslation(getAvailableNamespaces(), {
    useSuspense: false,
  });
  const userFavChatTopics = [];

  const userFavActivities = [];

  const getListToShow = (viewedList, userList) => {
    const listToShow = viewedList.reduce((acc, currentValue) => {
      const idUserList = userList.map(({ id }) => id);

      if (idUserList.find((item) => item === currentValue.id)) {
        const indexOfElement = idUserList.indexOf(currentValue.id);
        acc = [
          ...acc,
          {
            ...currentValue,
            index: indexOfElement,
            color: "#8649D1",
          },
        ];
        function compareNumbers(a, b) {
          return a.index - b.index;
        }
        acc.sort(compareNumbers);
      } else {
        acc = [
          ...acc,
          {
            ...currentValue,
            index: 10 + acc.length, //We have max 5 topics so 10 > 5
            color: "#00063D",
          },
        ];
      }
      return acc;
    }, []);
    return listToShow;
  };

  const favChatTopicsListToShow = getListToShow(
    viewedFavChatTopics,
    userFavChatTopics
  );

  const favActivitiesListToShow = getListToShow(
    viewedFavActivities,
    userFavActivities
  );

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.topicWrapper}>
        <span className={classes.iconWrapper}>
          <ChatTopics />
        </span>
        <div className={classes.listWrapper}>
          {favChatTopicsListToShow.map((item, index) => (
            <span
              className={classes.item}
              key={item.id}
              style={{ color: item.color }}
            >
              {item.name}
              {index < favChatTopicsListToShow.length - 1 && ","}
            </span>
          ))}
        </div>
      </div>
      <div className={classes.topicWrapper}>
        <span className={classes.iconWrapper}>
            <img
                className={classes.iconWrapper}
                src={activitiesIcon}
                alt="like"
            />
        </span>
        <div className={classes.listWrapper}>
          {favActivitiesListToShow.map((item, index) => (
            <span
              className={classes.item}
              key={item.id}
              style={{ color: item.color }}
            >
              {item.name}
              {index < favActivitiesListToShow.length - 1 && ","}
            </span>
          ))}
        </div>
      </div>
      {path && (
        <div className={classes.wishesWrapper}>
          <span className={classes.iconWrapper}>
            <WishToBecomeIcon />
          </span>
          <div className={classes.listWrapper}>
            {t("rookie_profile_page.wish_to_become")}
            {path?.name}
          </div>
        </div>
      )}
    </div>
  );
}

export default FavTopics;
