import React, { useState, useEffect } from "react";
import { createUseStyles, useTheme } from "react-jss";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../translations";
import LogoMorgis from "../assets/images/morgisLogo3x.png";
import LogoMorgisLight from "../assets/images/morgisLogoLight3x.png";
import {
  CloseIcon,
  CustomerSupportIcon,
  DocumentIcon,
  HomeIcon,
  MenuIcon,
  UserIcon,
  LoginIcon,
  HostsTourIcon,
} from "../assets/icons/icons";
import MenuItem from "./MenuItem";
import Menu from "./Menu";
import { useLocation } from "react-router-dom";
import Button from "./Button";

import {
  DIRECTIONS,
  PARAM_USER_GROUP,
  ROUTE_COMMUNITY_GUILDINES,
  ROUTE_COOKIE_POLICY,
  ROUTE_HOME,
  ROUTE_OUR_MISSION,
  ROUTE_PRIVACY_POLICY,
  ROUTE_WHAT_IS_MORGI,
  ROUTE_FAQ_LEADER,
  ROUTE_TOS_LEADER,
  ROUTE_SIGNUP_SPECIAL_HOST,
  PARAM_PERSONA,
  HOSTS_TOUR_GENERAL_LINK,
  HOSTS_TOUR_ATHLETES_LINK,
  HOSTS_TOUR_ARTISTS_SHOWBIZ_LINK,
  HOSTS_TOUR_ENTREPRENEURS_LINK,
  HOSTS_TOUR_INFLUENCERS_LINK,
  HOSTS_TOUR_LICENSED_PROFESSIONALS_LINK,
  HOSTS_TOUR_MODELS_LINK,
  HOSTS_TOUR_TEACHERS_COACHES_LINK,
  ROUTE_LANDING_ROOKIE,
  ROUTE_FAQ_ROOKIE,
} from "../utilities/constants";
import AboutUsTour from "./AboutUsTour";
import {
  APP_ROUTE_LOGIN_ROOKIE,
  APP_ROUTE_LOGIN_LEADER,
  APP_ROUTE_SIGNUP_LEADER_SOCIAL,
  APP_ROUTE_SIGNUP_ROOKIE_EMAIL,
} from "../utilities/constants/appRouteNames";
import { getAppLink, remapURLSearchParams } from "../utilities/utils";
import { useViewportSize } from "../hooks/useViewportSize";
import SubMenu from "./SubMenu";
import { getUserGroup } from "../utilities/constants/user";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomerlyVisible } from "../store/settingSlice";
import { LinkWithQuery } from "./LinkWithQuery";
import Select from "./Select";
import { NavLinkWithQuery } from "./NavLinkWithQuery";

const useStyles = createUseStyles((theme) => ({
  root: {
    position: "relative",
    top: 0,
    right: 0,
    left: 0,
    height: theme.sizing.headerHeight,
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    alignItems: "center",
    gridAutoFlow: "auto",
    gridColumnGap: theme.spacing * 2,

    [theme.sUp]: {
      padding: 0,
      height: theme.sizing.headerSmallUpHeight,
    },
    "& a": {
      color: theme.colors.black,
    },
  },
  header: {
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    alignItems: "center",
    padding: [theme.spacing * 2],
    height: theme.sizing.headerHeight,
    [theme.mUp]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  navbarToggleWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing * 2,
    alignItems: "center",
    [theme.mUp]: {
      gridTemplateColumns: "auto 1fr",
    },
  },
  navbarToggler: {
    cursor: "pointer",
    "-webkit-tap-highlight-color": "rgba(255, 255, 255, 0)",
    justifySelf: "start",
    border: "none",
    background: "transparent",
    padding: 0,

    "&:focus": {
      outline: "none",
    },
    "& svg": {
      width: "20px",
    },
  },

  navbarCollapse: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1030,
    textAlign: "left",
    padding: 0,
    position: "fixed",
    //top: theme.sizing.headerHeight,
    top: 0,
    left: 0,
    width: "100vw",
    //minHeight: `calc(100vh - ${theme.sizing.headerHeight})`,
    height: ({ vh }) => `calc((${vh}px * 100))`,
    overflowY: "scroll",
    transform: ({ open, direction }) =>
      open
        ? `translateX(0)`
        : `translateX(${direction === DIRECTIONS.RTL ? "100%" : "-100%"})`,
    opacity: ({ open }) => (open ? 1 : 0),
    transition: "all 0.3s ease-in-out",
    backgroundColor: `${theme.colors.white}`,
    [theme.mUp]: {
      width: "40vw",
      maxWidth: 480,
    },
  },
  navbarBrand: {
    gridColumnStart: 2,
    "& img": {
      width: "60px",
      [theme.mUp]: {
        width: "80px",
      },
    },
  },
  navbarCollapseBrand: {
    "& img": {
      width: "60px",
    },
    [theme.mUp]: {
      display: "none",
    },
  },
  navItem: {
    display: "grid",
    gridAutoFlow: "column",
    justifySelf: "end",
    gridColumnGap: theme.spacing,
    "& > a > svg": {
      width: 20,
      height: 20,
      [theme.mUp]: {
        width: theme.spacing * 3,
        height: theme.spacing * 3,
      },
    },
  },
  overlay: {
    position: "fixed",
    zIndex: 1029,
    display: ({ open }) => (open ? "block" : "none"),
    backgroundColor: "rgba(0,0,0,0.5)",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
  },
  navIcon: {
    fill: ({ isLight }) =>
      isLight ? theme.colors.darkPurple : theme.colors.white,
  },
  langSelect: {
    width: "67px",
    height: "33px",
    marginRight: "15px",
  }
}));

const Navbar = ({ className, isLight, ...props }) => {
  const [t, i18n] = useTranslation(getAvailableNamespaces(), {
    useSuspense: false,
  });
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showAboutTour, setShowAboutTour] = useState(false);
  const { search } = useLocation();
  const role = location.pathname === ROUTE_LANDING_ROOKIE ? "rookie" : "leader";
  const isRookie = role === "rookie"
  const { vh } = useViewportSize();

  const queryString = new URLSearchParams(search);

  const classes = useStyles({ vh, open, theme, direction: theme.rtl, isLight });

  const languagesOptions = Object.keys(i18n.store.data).map((item, idx) => {
    return {
      name: item.toUpperCase().split("_")[0],
      value: item,
    };
  });

  //this is for the special host signup if the user is persona 10, otherwise it will be the leader signup
  const searchParams = new URLSearchParams(location?.search);

  const urlContainPersona10 = +searchParams.get(PARAM_PERSONA) === 10;

  const urlToRedirect = urlContainPersona10
    ? ROUTE_SIGNUP_SPECIAL_HOST
    : APP_ROUTE_SIGNUP_LEADER_SOCIAL;

  const onChangeLanguage = (value) => {
    i18n.changeLanguage(value);
    //dispatch(changeLng({lang: i18n.language, direction: i18n.dir()}))
  };

  useEffect(() => {
    // Stop body from scrolling when menu is open
    window.document.body.style["overflow-y"] = open ? "hidden" : "auto";
  }, [open]);

  /* AboutUs Tour can be opened only from here*/
  const handleAboutUsClick = () => {
    setShowAboutTour(true);
    setOpen(false);
  };

  const handleCustomerSupport = () => {
    const isHost = location.pathname.includes('host')
    window && window.open(isHost ? "https://hostsupport.morgis.com/" : "https://support.morgis.com/", "_blank");
    setOpen(!open);
  };

  const handleMenuItemClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <nav className={cx(classes.root, className)}>
        <div className={classes.overlay} onClick={() => setOpen(!open)} />
        <div className={classes.navbarToggleWrapper}>
          <button
            className={classes.navbarToggler}
            onClick={() => setOpen(!open)}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <MenuIcon className={classes.navIcon} />
          </button>
        </div>
        <div
          className={classes.navbarBrand}
          onClick={() => localStorage.setItem("slideIndex", 0)}
        >
          <LinkWithQuery to={isRookie ? ROUTE_LANDING_ROOKIE : ROUTE_HOME}>
            <img src={isLight ? LogoMorgis : LogoMorgisLight} />
            {/* <MorgiLogo isLight={!isLight} /> */}
          </LinkWithQuery>
        </div>
        <div className={classes.navbarCollapse}>
          <div className={classes.header}>
            <button
              className={classes.navbarToggler}
              onClick={() => setOpen(!open)}
              type="button"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <CloseIcon />
            </button>
            <div className={classes.navbarCollapseBrand}>
              <LinkWithQuery to={isRookie ? ROUTE_LANDING_ROOKIE : ROUTE_HOME}>
                <img src={LogoMorgis} alt={"logo"} />
              </LinkWithQuery>
            </div>
            <div className={classes.navItem}>
              <Select
                onChange={onChangeLanguage}
                defaultValue={{
                  name: i18n.language.toUpperCase().split("_")[0],
                  value: i18n.language,
                }}
                options={languagesOptions}
                className={classes.langSelect}
              />
            </div>
          </div>
          <Menu isOpen={open}
            footer={
              <div >
                <NavLinkWithQuery to={isRookie ? ROUTE_HOME : ROUTE_LANDING_ROOKIE}>
                  <span>
                    {isRookie ? t('menu.to_the_guest_side') : t('menu.to_the_rookie_side')}
                  </span>
                </NavLinkWithQuery>
              </div>
            }>
            {/* Anonymous Menu */}
            <MenuItem
              style={{ fontWeight: 700 }}
              icon={HomeIcon}
              to={"/"}
              title={t("menu.home")}
              onClick={handleMenuItemClick}
            />
            <MenuItem
              icon={LoginIcon}
              style={{ fontWeight: 700 }}
              onClick={handleMenuItemClick}
            >
              <a
                style={{ textDecoration: "none", width: "100%", lineHeight: 2 }}
                href={getAppLink(isRookie ? APP_ROUTE_LOGIN_ROOKIE : APP_ROUTE_LOGIN_LEADER, [
                  ...remapURLSearchParams(search),
                  {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                  },
                ])}
              >
                {isRookie ? t("menu.login_rookie") : t("menu.login_leader")}
              </a>
            </MenuItem>

            <MenuItem
              icon={UserIcon}
              style={{ fontWeight: 700 }}
              onClick={handleMenuItemClick}
            >
              <a
                style={{ textDecoration: "none", width: "100%", lineHeight: 2 }}
                href={getAppLink(isRookie ? APP_ROUTE_SIGNUP_ROOKIE_EMAIL : APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
                  ...remapURLSearchParams(search),
                  {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                  },
                ])}
              >
                {isRookie ? t("menu.signup_as_rookie") : t("menu.signup_as_leader")}
              </a>
            </MenuItem>


            {isRookie && <SubMenu
              menuOpen={open}
              collapsible
              icon={HostsTourIcon}
              defaultOpen={false}
              title={t("menu.hosts_tour")}
            >
              <MenuItem
                title={t("menu.general")}
                onClick={(event) => {
                  event.preventDefault();
                  window.location.href = HOSTS_TOUR_GENERAL_LINK;
                  handleMenuItemClick()
                }}
              />
              <MenuItem
                title={t("menu.athletes")}
                onClick={(event) => {
                  event.preventDefault();
                  window.location.href = HOSTS_TOUR_ATHLETES_LINK;
                  handleMenuItemClick()
                }}
              />
              <MenuItem
                title={t("menu.artists_&_showbiz")}
                onClick={(event) => {
                  event.preventDefault();
                  window.location.href = HOSTS_TOUR_ARTISTS_SHOWBIZ_LINK;
                  handleMenuItemClick()
                }}
              />
              <MenuItem
                title={t("menu.entrepreneurs")}
                onClick={(event) => {
                  event.preventDefault();
                  window.location.href = HOSTS_TOUR_ENTREPRENEURS_LINK;
                  handleMenuItemClick()
                }}
              />
              <MenuItem
                title={t("menu.influencers")}
                onClick={(event) => {
                  event.preventDefault();
                  window.location.href = HOSTS_TOUR_INFLUENCERS_LINK;
                  handleMenuItemClick()
                }}
              />
              <MenuItem
                title={t("menu.licensed_professionals")}
                onClick={(event) => {
                  event.preventDefault();
                  window.location.href = HOSTS_TOUR_LICENSED_PROFESSIONALS_LINK;
                  handleMenuItemClick()
                }}
              />
              <MenuItem
                title={t("menu.models")}
                onClick={(event) => {
                  event.preventDefault();
                  window.location.href = HOSTS_TOUR_MODELS_LINK;
                  handleMenuItemClick()
                }}
              />
              <MenuItem
                title={t("menu.teachers_&_coaches")}
                onClick={(event) => {
                  event.preventDefault();
                  window.location.href = HOSTS_TOUR_TEACHERS_COACHES_LINK;
                  handleMenuItemClick()
                }}
              />


            </SubMenu>}

            <MenuItem
              style={{ fontWeight: 700 }}
              to={isRookie ? ROUTE_FAQ_ROOKIE : ROUTE_FAQ_LEADER}
              title={t("menu.faq")}
              onClick={handleMenuItemClick}
            />
            <MenuItem
              style={{ fontWeight: 700 }}
              to={ROUTE_WHAT_IS_MORGI}
              title={t("menu.what_is_morgi")}
              onClick={handleMenuItemClick}
            />
            {/*<MenuItem style={{ fontWeight: 700}} to={ROUTE_A_WORD_FROM_PRESIDENT} title={t('menu.word_from_president')}/>*/}
            <MenuItem
              style={{ fontWeight: 700 }}
              to={ROUTE_OUR_MISSION}
              title={t("menu.mission")}
              onClick={handleMenuItemClick}
            />
            <MenuItem
              style={{ fontWeight: 700 }}
              onClick={handleAboutUsClick}
              title={t("menu.about_us")}
            />

            <SubMenu
              menuOpen={open}
              collapsible
              icon={DocumentIcon}
              defaultOpen={false}
              title={t("menu.legal")}
            >
              <MenuItem
                to={ROUTE_TOS_LEADER}
                title={t("menu.tos")}
                onClick={handleMenuItemClick}
              />
              <MenuItem
                to={ROUTE_COMMUNITY_GUILDINES}
                title={t("menu.community_guidelines")}
                onClick={handleMenuItemClick}
              />
              <MenuItem
                to={ROUTE_PRIVACY_POLICY}
                title={t("menu.privacy_policy")}
                onClick={handleMenuItemClick}
              />
              <MenuItem
                to={ROUTE_COOKIE_POLICY}
                title={t("menu.cookie_policy")}
                onClick={handleMenuItemClick}
              />
            </SubMenu>
            <MenuItem
              icon={CustomerSupportIcon}
              onClick={handleCustomerSupport}
              style={{ fontWeight: 700 }}
              title={t("menu.customer_support")}
            />

          </Menu>
        </div>
        {
          <div className={classes.navItem}>
            <Button data-secondary data-small fontSize={16}>
              <a
                style={{ textDecoration: "none" }}
                href={
                  location?.pathname === ROUTE_HOME
                    ? getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
                      ...remapURLSearchParams(location?.search),
                    ])
                    : getAppLink(urlToRedirect, [
                      ...remapURLSearchParams(search),
                      {
                        name: PARAM_USER_GROUP,
                        value: getUserGroup(),
                      },
                    ])
                }
              >
                {t("menu.signup")}
              </a>
            </Button>
          </div>
        }
      </nav>
      {showAboutTour && (
        <AboutUsTour closeCallback={() => setShowAboutTour(false)} />
      )}
    </>
  );
};

export default Navbar;
